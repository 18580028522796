import React from 'react'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import { graphql } from 'gatsby'

const Template = ({ data }) => {
  const d = data.allGeneralYaml.edges[0].node

  return (
    <Layout
      bodyClass="k-reverse-header">
      <SEO
        title={d.title}
        keywords={d.meta.keywords}
        description={d.meta.description} />
      <div className="k-py-menu" />
      <div className="container-fluid k-max-width--1">
        <div className="k-section">
          <header>
            <h1>{d.title}</h1>
          </header>
          <main>
            <div
              className="k-markdown-content"
              dangerouslySetInnerHTML={{ __html: d.content }} />
          </main>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    allGeneralYaml(filter: { path: { eq: $path } }) {
      edges {
        node {
          path
          title
          meta {
            keywords
            description
          }
          content
        }
      }
    }
  }
`

export default Template
